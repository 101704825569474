import { termsofServics } from '../../Constant'

function Termofservice() {
  return (
    <div className="privacy-content">
      <div className="container">
        <div className="heading">
          <h2>Terms of Use for<br /> Rake Rumble</h2>
        </div>
        <div className="privacy-content-list">
          {termsofServics.map((item, index) => {
            return (
              <>
                <h3 key={index}>{item.title}</h3>
                {item.para.map((des, indx) => {
                  return (
                    <p key={indx}>
                      {des.text} {des.linkText && <a href={des.link}>{des.linkText}</a>}
                    </p>
                  )
                })}
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Termofservice
