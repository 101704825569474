import Image from '../image'
import Typography from '../typography'

function GridInfo({ extraClass, data }) {
  return (
    <section className={`${extraClass} blacktext gap`}>
      <div className="container flex">
        <div className="row">
          <div className="col2">
            <h2 className="mgbtm">
              {data.title} <br /> {data.subTitle && data.subTitle}
            </h2>
            <Typography text={data.description} variant="text" />
            {data.secondDescription && <Typography text={data.secondDescription} variant="text" />}
            {data.thirdDescription && <Typography text={data.thirdDescription} variant="text" />}
          </div>
          <div className="col2">
            <Image src={data.image} alt={data.alt} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default GridInfo
