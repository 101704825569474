import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import Error from '../Screens/Error/Error'
import Home from '../Screens/Home'
import Termofservice from '../Screens/termsOfServices'
import Privacy from '../Screens/privacy'
import WorldMap from '../Screens/world-map'

const Routes = () => {
  return useRoutes([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/world-map',
      element: <WorldMap />
    },
    {
      path: '/term-of-service',
      element: <Termofservice />
    },
    {
      path: '/privacy',
      element: <Privacy />
    },
    { path: '404', element: <Error /> },
    {
      path: '*',
      element: <Navigate to="/404" />
    }
  ])
}

export default Routes
