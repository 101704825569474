import { Link } from 'react-router-dom'
import Logo from '../../Assets/images/rakerumble_Footer.svg'
import { footerLink } from '../../Constant'
import Button from '../button'

function Footer() {
  const handleClick = () => {
    window.open('https://rake.com/', '_blank', 'noreferrer')
  }
  return (
    <footer>
      <div className="container flex">
        <div className="row">
          <div className="column full-column">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
            <p>
              Rake Rumble is a free-to-play MMORPG. Master skills, trade with Rake Coin, and embark
              on epic adventures.
            </p>
            <Button title="Play Now" onClick={handleClick} />
          </div>
          {footerLink.map((item, index) => {
            return (
              <div className="column" key={index}>
                <h5>{item.title}</h5>
                {item.links && (
                  <ul>
                    {item.links.map((link, indx) => {
                      return (
                        <li key={indx}>
                          {link.target ? (
                            <a href={link.link} target="_blank" rel="noreferrer">
                              {link.title}
                            </a>
                          ) : (
                            <Link to={link.link}>
                              <img src="" alt="" /> {link.title}
                            </Link>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                )}
                {item.soicalLinks && (
                  <ul className="socl">
                    {item.soicalLinks.map((item, index) => {
                      return (
                        <a href={item.link} key={index} target="_blank" rel="noreferrer">
                          <img src={item.image} alt={item.altText} />
                        </a>
                      )
                    })}
                  </ul>
                )}
              </div>
            )
          })}
        </div>
        <div className="row copyrght">
          <p>Rake Rumble © 2024</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
