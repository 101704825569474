import Image from '../../../Components/image'
import Typography from '../../../Components/typography'
import { buySellTrade } from '../../../Constant'

function BuySell() {
  return (
    <section className="buysell gap">
      <div className="container flex">
        <div className="row text-center">
          <Typography text="buy, sell, & trade" variant="h2" extraClasses="mgbtm" />
        </div>
        <div className="row">
          {buySellTrade.map((item, index) => {
            return (
              <div className="col2" key={index}>
                <Image src={item.image} alt={item.title} />
                <Typography text={item.title} variant="h3" />
                <Typography text={item.description} variant="text" />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default BuySell
