import rakerumble from '../../../Assets/images/rakerumble_logo.svg'
import arrowdown from '../../../Assets/images/arrow-down.svg'
import Button from '../../../Components/button'
import Typography from '../../../Components/typography'
import Image from '../../../Components/image'

function Hero() {
  const handleClick = () => {
    window.open('https://rake.com/', '_blank', 'noreferrer')
  }
  return (
    <section className="herosect">
      <div className="container flex">
        <div className="row text-center">
          <img src={rakerumble} alt="rakerumble" />
          <Typography
            text="Master your skills, embrace the journey, and become a legend."
            variant="h1"
          />
          <Button title="Play Now" onClick={handleClick} />
          <Typography text="Experience the adventure exclusively on Rake.com" variant="text" />
        </div>
        <div className="row arpost">
          <a href="/#bottom" className="btm">
            <p>Explore</p>
            <Image src={arrowdown} alt="arrow-down" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Hero
