import React from 'react'
import Hero from './Sections/hero'
import Explore from './Sections/explore'
import Discover from './Sections/discover'
import Buysell from './Sections/buysell'
import RakeCoins from './Sections/rakecoins'
import Burn from './Sections/burn'
import Collect from './Sections/collect'
import './style.css'

function Home() {
  return (
    <div className="homem">
      <Hero />
      <Explore />
      <Discover />
      <Buysell />
      <RakeCoins />
      <Burn />
      <Collect />
    </div>
  )
}

export default Home
