import Button from '../../Components/button'
import Hero from './sections/hero'
import Map from './sections/map'
import './style.css'

function WorldMap() {
  const handleClick = () => {
    window.open('https://rakerumble.com/map/map.png', '_blank', 'noreferrer')
  }
  return (
    <div className="world-map">
      <Hero />
      <Map />
      <Button title="click to view full world map" onClick={handleClick} />
    </div>
  )
}

export default WorldMap
