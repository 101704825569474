import React from 'react'
import Typography from '../../../Components/typography'

function Hero() {
  return (
    <div className="world-map-hero">
      <Typography text="World map" variant="h2" />
      <Typography text="Welcome to Rake Rumble " variant="h3" />
    </div>
  )
}

export default Hero
