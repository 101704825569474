import Image from '../../../Components/image'
import Typography from '../../../Components/typography'
import { burn } from '../../../Constant'

function burnR() {
  return (
    <section className="burn  gap"> 
      <div className="container flex">
        <div className="row">
          <div className="col2">
            <Image src={burn.image} alt="burn" />
          </div>
          <div className="col2">
            <Typography text={burn.title} variant="h2" extraClasses="mgbtm" />
            <Typography text={burn.subTitle} variant="h3" />
            <Typography text={burn.description} variant="text" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default burnR
