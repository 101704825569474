import fb from '../Assets/images/social_fb.svg'
import ig from '../Assets/images/social_ig.svg'
import twitter from '../Assets/images/social_twitter.svg'
import yt from '../Assets/images/social-yt.svg'
import tel from '../Assets/images/telegram.svg'
import RakeCoins from '../Assets/images/RakeCoins.svg'
import discover from '../Assets/images/discover.svg'
import burnImg from '../Assets/images/burn.svg'
import Central from '../Assets/images/Central.svg'
import Decentralized from '../Assets/images/Decentralized.svg'
import coins from '../Assets/images/coins.svg'
import axe from '../Assets/images/axe.svg'
import dice from '../Assets/images/dice.svg'

export const footerLink = [
  {
    title: 'Available on',
    links: [
      {
        title: 'Uniswap',
        link: 'https://app.uniswap.org/tokens/ethereum/0x6553565eac5daa9bfc5e2892b36291634c9b2ad6',
        target: true
      },
      {
        title: 'Sushiswap',
        link: 'https://www.sushi.com/swap?chainId=1&token0=NATIVE&token1=0x6553565EaC5DAa9BfC5E2892B36291634c9B2ad6',
        target: true
      },
      {
        title: 'MEXC',
        link: 'https://www.mexc.com/exchange/RAKE_USDT',
        target: true
      },
      {
        title: 'BITMART',
        link: 'https://www.bitmart.com/trade/en-US?symbol=RAKE_USDT',
        target: true
      }
    ]
  },
  {
    title: 'Support',
    links: [
      {
        title: 'Terms of Use',
        link: '/term-of-service'
      },
      {
        title: 'Privacy Policy',
        link: '/privacy'
      }
    ]
  },
  {
    title: 'Socials',
    soicalLinks: [
      {
        image: fb,
        altText: 'Facebook',
        link: 'https://www.facebook.com/RakeCasino'
      },
      {
        image: ig,
        altText: 'instagram',
        link: 'https://www.instagram.com/rakecasino'
      },
      {
        image: twitter,
        altText: 'twitter',
        link: 'https://twitter.com/rakerumble '
      },
      {
        image: yt,
        altText: 'youtube',
        link: ''
      },
      {
        image: tel,
        altText: 'telegram',
        link: 'https://t.me/+ryP9DDl-v6UwZTRh'
      }
    ]
  }
]

export const RakeCoinsInfo = {
  image: RakeCoins,
  title: 'RAKE',
  subTitle: 'COINS',
  description:
    'Your wallet always holds Rake Coin, and its value reflects as Rake Gold based on the amount of Rake Coin in your Rake.com Casino wallet. 1 Rake Coin equals 1000 Rake Gold, simplifying transactions without decimals.',
  alt: 'Rake Coins'
}

export const DiscoverInfo = {
  image: discover,
  title: 'DISCOVER',
  description:
    'Traverse the realms of Rake Rumble, from the serene Isle of Tharion to the bustling streets of Eldrastan.',
  secondDescription:
    'Navigate through mystic swamps and uncover ancient secrets, but be wary of the north where the Wilderness looms, promising riches or ruin in player vs. player combat.',
  thirdDescription:
    'In Duelhaven, the Battle Bazaar calls, a place to challenge foes or wager on outcomes. Each step is a chance to build your legend, culminating in the dangerous Wilderness, where only the bravest prevail.',
  alt: 'Rake Coins'
}

export const burn = {
  image: burnImg,
  title: 'burn',
  subTitle: 'Standard Burn Rate: 1% ',
  description: `When you trade through the in-game exchange in Rake Rumble, there's a standard burn
    rate of 1% applied to Rake Coin transactions. This means that 1% of the Rake Coin used
    in each trade is permanently removed from circulation. Keep an eye out for our unique
    visual representation of this burn, coming soon!
  `
}

export const buySellTrade = [
  {
    image: Central,
    title: 'Central Marketplace',
    description:
      "The Central Marketplace is where all in-game items in Rake Rumble are traded. From the most common resources to the rarest armors, players have the freedom to buy, sell, and set prices. It's an open economy driven by the community. For those who prefer direct dealings, player-to-player trades offer a more personal exchange."
  },
  {
    image: Decentralized,
    title: 'Decentralized Bazaar',
    description:
      "For exclusive transactions, the Decentralized Bazaar is the go-to for trading Rake Rumble's rare items, including NFTs. These prized possessions can be showcased in-game, stored as digital collectibles, or sold. The Bazaar provides a secure environment for players to conduct transactions involving these valuable assets. "
  }
]

export const exploreInfo = {
  title: 'explore',
  subTitle: 'The Rake Gaming Universe',
  des: [
    `Immerse yourself in Rake Rumble, the dynamic centerpiece of Rake.com's gaming
    universe.`,
    ` Join over 20,000 adventurers already charting their epic journeys. The recent 65% Rake
    Coin airdrop has infused the in-game economy, priming it for new challenges and
    achievements. Step into a world where your skill mastery and combat prowess shape your
    story.`
  ],
  exploreIconList: [
    {
      image: coins,
      alt: 'coins',
      description:
        ' Rake Gold, linked to Rake Coin, is the heartbeat of our vibrant in-game marketplace.'
    },
    {
      image: axe,
      title: 'axe',
      description:
        'Train diverse skills, engage in strategic trades, and battle formidable monsters.'
    },
    {
      image: dice,
      alt: 'dice',
      description:
        'Every decision and action weaves into the fabric of this rich, player-driven experience.'
    }
  ]
}

export const termsofServics = [
  {
    title: 'Introduction',
    para: [
      {
        text: 'Welcome to Rake Rumble, a captivating MMORPG experience provided to you by Elixir Tech N.V., a company registered in Curaçao. Your access to and use of Rake Rumble ("Game") are conditioned on your acceptance of and compliance with these Terms.'
      }
    ]
  },
  {
    title: 'Acceptance of Terms',
    para: [
      {
        text: 'By accessing or using the Game, you signify that you have read, understood, and agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Game.'
      }
    ]
  },
  {
    title: 'Account Registration and Security',
    para: [
      {
        text: 'Upon accessing the Game through our Site, which redirects to our Casino platform, an account will be automatically generated for you. You are responsible for safeguarding the password and for all activities that occur under your account.'
      }
    ]
  },
  {
    title: 'Content Ownership and Intellectual Property Rights',
    para: [
      {
        text: 'All content present in the Game, including visual interfaces, graphics, design, systems, methods, information, computer code, software, services, "look and feel", organization, compilation of the content, code, data, and all other elements of the Game are owned by Elixir Tech N.V. Use of the Game does not grant you ownership of any content, code, data, or materials you may access.'
      }
    ]
  },
  {
    title: 'User Conduct and Fair Play',
    para: [
      {
        text: 'We expect all players to adhere to the principles of fair play. This includes, but is not limited to, refraining from cheating, exploiting bugs or glitches, engaging in abusive or disruptive behavior, and respecting fellow players.'
      }
    ]
  },
  {
    title: 'Prohibited Activities',
    para: [
      {
        text: 'The following activities are expressly prohibited:'
      },
      {
        text: 'Using the Game for any commercial solicitation purposes.'
      },
      {
        text: 'Accessing, tampering with, or using non-public areas of the Game.'
      },
      {
        text: 'Attempting to probe, scan, or test the vulnerability of any system or network.'
      },
      {
        text: 'Breaching or circumventing any security or authentication measures.'
      }
    ]
  },
  {
    title: 'Disclaimers',
    para: [
      {
        text: 'The Game is provided on an "as is" and "as available" basis. Elixir Tech N.V. expressly disclaims any warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.'
      }
    ]
  },
  {
    title: 'Limitation of Liability',
    para: [
      {
        text: 'To the maximum extent permitted by applicable law, Elixir Tech N.V. shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.'
      }
    ]
  },
  {
    title: 'Termination of Use',
    para: [
      {
        text: 'Elixir Tech N.V. may terminate or suspend your account immediately, without prior notice or liability, for any reason, including without limitation if you breach the Terms.'
      }
    ]
  },
  {
    title: 'Governing Law and Jurisdiction',
    para: [
      {
        text: 'These Terms shall be governed by the laws of Curaçao. You agree that any judicial proceedings will be brought in and you hereby consent to the exclusive jurisdiction and venue in such courts.'
      }
    ]
  },
  {
    title: 'Changes to the Terms',
    para: [
      {
        text: 'We reserve the right to modify or replace these Terms at any time at our sole discretion. Your continued use of the Game after any such changes constitutes your acceptance of the new Terms.'
      }
    ]
  },
  {
    title: 'Contact Information',
    para: [
      {
        text: 'Should you have any questions regarding these Terms, please contact us at',
        link: 'mailto:support@rakerumble.com',
        linkText: 'support@rakerumble.com.'
      }
    ]
  },
  {
    title: 'Entire Agreement',
    para: [
      {
        text: 'These Terms constitute the entire agreement between you and Elixir Tech N.V. regarding the Game, and supersede and replace any prior agreements we might have between us regarding the Game.'
      }
    ]
  },
  {
    title: 'Feedback and Suggestions',
    para: [
      {
        text: 'If you provide Elixir Tech N.V. with any feedback or suggestions regarding the Game, you hereby assign to Elixir Tech N.V. all rights in such feedback and agree that Elixir Tech N.V. shall have the right to use and fully exploit such feedback and related information in any manner it deems appropriate.'
      }
    ]
  },
  {
    title: 'Miscellaneous',
    para: [
      {
        text: 'Severability: If any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect.'
      },
      {
        text: "Waiver: No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Elixir Tech N.V.'s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision."
      }
    ]
  }
]

export const privacycontent = [
  {
    title: 'Effective Date: [10-01-2024]',
    para: [
      {
        text: 'This Privacy Policy describes how Elixir Tech N.V. ("Company", "we", "us", or "our"), the owner and operator of the game Rake Rumble, accessible through rakerumble.com, collects, uses, and discloses information about users of its services ("you").'
      }
    ]
  },
  {
    title: 'Information We Collect',
    subtitle: 'Personal Information',
    para: [
      {
        strongText: 'Registration Information:',
        text: 'When you sign up to play Rake Rumble, we collect the information you provide, such as your username, email address, and any other information necessary for the setup of your in-game account.'
      },
      {
        strongText: 'Payment Information:',
        text: 'If you make any purchases within the game, we will collect transactional information, including payment details, necessary to process your purchases. Usage Information'
      },
      {
        strongText: 'Game Activity:',
        text: 'We collect information about your game progress, scores, and interactions with other players.'
      },
      {
        strongText: 'Technical Data: ',
        text: 'This includes your IP address, device type, operating system, game version, and unique device identifiers.'
      },
      {
        strongText: 'Cookies and Tracking Technologies:',
        text: 'We use cookies and similar tracking technologies to track activity within our game and hold certain information.'
      }
    ]
  },
  {
    title: 'How We Use Your Information',
    para: [
      {
        strongText: 'To provide and maintain the game: ',
        text: 'Including updates and other related services.'
      },
      {
        strongText: 'To manage user accounts:',
        text: ' We use your information to manage your registration and your in-game account.'
      },
      {
        strongText: 'To process transactions: ',
        text: 'Your information allows us to process payments for in-game purchases.'
      },
      {
        strongText: 'To communicate with you:',
        text: 'We use your information to contact you with game updates, technical notices, support messages, and security alerts.'
      },
      {
        strongText: 'To personalize your game experience: ',
        text: 'Information collected helps us tailor the game content to your preferences.'
      },
      {
        strongText: 'For analytics and game improvement: ',
        text: 'We analyze user activity to improve Rake Rumble and develop new features.'
      }
    ]
  },
  {
    title: 'Sharing of Your Information',
    para: [
      {
        text: 'We may share your information with subsidiaries, affiliated companies, or third-party service providers to carry out tasks on our behalf.'
      },
      {
        text: 'We may also share your information if required by law, to enforce our policies or protect our rights, property, or safety, or that of our players.'
      }
    ]
  },
  {
    title: 'Data Retention',
    para: [
      {
        text: 'We retain your personal information as long as necessary to provide you with our services and as needed for legal or operational purposes.'
      }
    ]
  },
  {
    title: 'Your Data Protection Rights',
    para: [
      {
        text: 'Depending on your jurisdiction, you may have the right to access, correct, delete, or restrict the use of your personal data.'
      }
    ]
  },
  {
    title: 'Security of Data',
    para: [
      {
        text: 'We take data security seriously and implement measures to protect your information from unauthorized access, alteration, disclosure, or destruction.'
      }
    ]
  },
  {
    title: 'International Transfers',
    para: [
      {
        text: 'Your information may be transferred to, and maintained on, computers located outside of your state or country where data protection laws may differ.'
      }
    ]
  },
  {
    title: `Children's Privacy and Age Restrictions`,
    para: [
      {
        text: `Rake Rumble is designed for players aged 6 and older. However, the game is not intended for children under the legal age to bet or gamble, which varies by jurisdiction.
        We do not knowingly collect personally identifiable information from children under the legal age to bet. If you are under the legal age to bet, you may be restricted to playing Rake Rumble and may be prohibited from accessing other betting or casino games on our platform.
        `
      },
      {
        text: `If we learn that we have collected personal information from a child under the legal age to bet without verification of parental consent, we take steps to remove that information and restrict the account as appropriate. `
      }
    ]
  },
  {
    title: 'Legal Age to Bet',
    para: [
      {
        text: 'If you are under the legal age to bet according to the laws of your jurisdiction, you are not permitted to bet or gamble on our platform. We reserve the right to restrict your account to prevent access to betting and gambling services, allowing access solely to the game Rake Rumble, which is rated for players aged 6 and older.'
      },
      {
        text: 'It is the responsibility of each user to ensure they are of legal betting age in their jurisdiction when attempting to engage in betting activities on our platform.'
      }
    ]
  },
  {
    title: 'Changes to This Privacy Policy',
    para: [
      {
        text: 'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.'
      }
    ]
  },
  {
    title: 'Contact Us',
    para: [
      {
        text: 'If you have any questions about this Privacy Policy, please contact us at',
        link: 'mailto:support@rakerumble.com',
        linkText: 'support@rakerumble.com.'
      }
    ]
  }
]
