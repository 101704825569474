import collectbg from '../../../Assets/images/collectbg.jpg'
import Image from '../../../Components/image'
import Typography from '../../../Components/typography'

function collect() {
  return (
    <section className="collect  gap">
      <div className="container flex">
        <div className="row text-center">
          <div className="col12">
            <Typography text="Collect" variant="h2" extraClasses="mgbtm" />
            <Typography text="The Most Rare NFT Sale in Rake Rumble" variant="h3" />
            <Typography
              text="Get ready for the upcoming event in Rake Rumble! Our exclusive NFT sale is on the
              horizon, offering 20,000 unique items, each with its distinct rarity. Among them, the
              enigmatic Riddle Rucksack holds in-game surprises. Don't miss the chance to own a
              piece of Rake Rumble history and enhance your gaming journey. Stay tuned for more
              details on how to secure these limited-edition collectibles."
              variant="text"
            />
          </div>
        </div>
      </div>
      <div>
        <Image src={collectbg} alt="collectbg" />
      </div>
    </section>
  )
}

export default collect
