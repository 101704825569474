import MapImg from '../../../Assets/images/Map_icon_Final.png'
import Image from '../../../Components/image'

function Map() {
  return (
    <div className="map">
      <div className="container flex">
        <Image src={MapImg} alt="Map img" style={{ width: '100%' }} />
      </div>
    </div>
  )
}

export default Map
