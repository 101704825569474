/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../Assets/images/Logo.svg'
import favR from '../../Assets/images/favR.svg'
import grarrow from '../../Assets/images/grarrow.svg'
import ToggleMenu from '../../Assets/images/toggle-menu.svg'
import CloseMenu from '../../Assets/images/close-menu.svg'

function Header() {
  const { pathname } = useLocation()
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [isMenu, setIsMenu] = useState(false)
  const [data, setData] = useState({
    price: '0.0000',
    percentage: '0'
  })
  const [error, setError] = useState('')
  const apiUrl = 'https://rakerumble.com/rake-api/crypto_currency_exchange.php'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl)
        const data = await response.json()
        if (data) {
          setData({
            price: data?.price,
            percentage: data?.percentageChange
          })
        }
      } catch (error) {
        setError('Something went wrong')
      }
    }

    fetchData()

    const interval = setInterval(() => {
      fetchData()
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSmallScreen(true)
      } else {
        setIsSmallScreen(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
  }, [isSmallScreen])

  useEffect(() => {
    if (isMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isSmallScreen, isMenu])

  return (
    <header>
      <div className="main-head flex">
        <div className="container-fluid">
          <div className="row">
            <div className="flex">
              <Link to="/" className="logosec">
                <img src={Logo} alt="Rake Rumble" />
              </Link>
              {!isSmallScreen && (
                <>
                  {' '}
                  <Link to="/" className={`nav-menu ${pathname === '/' ? 'active' : ''}`}>
                    Home
                  </Link>
                  <Link
                    to="/world-map"
                    className={`nav-menu ${pathname === '/world-map' ? 'active' : ''}`}>
                    World Map
                  </Link>
                </>
              )}
              {isSmallScreen && (
                <span className="toggle">
                  <img
                    src={isMenu ? CloseMenu : ToggleMenu}
                    alt="toggle icon"
                    onClick={() => setIsMenu(!isMenu)}
                  />
                </span>
              )}
            </div>
            <div className={`flex nav-menu ${isMenu ? 'active' : ''}`}>
              {isSmallScreen && (
                <>
                  <Link to="/" className={`nav-menu ${pathname === '/' ? 'active' : ''}`}>
                    Home
                  </Link>
                  <Link
                    to="/world-map"
                    className={`nav-menu ${pathname === '/world-map' ? 'active' : ''}`}>
                    World Map
                  </Link>
                </>
              )}
              <a className="btn" target="_blank" rel="noreferrer" href="https://rake.com">
                Play Now{' '}
              </a>
              <div className="rakec">
                <img src={favR} alt="favR" />
                <div className="lerak">
                  <p>
                    <b>Rake Coin</b> RAKE
                  </p>
                  {error ? (
                    <p>{error}</p>
                  ) : (
                    <span>
                      ${Number.parseFloat(data?.price).toFixed(7)}
                      {/* {?.toFixed(7)} */}
                      <span className={data?.percentage < 0 ? 'red' : 'green'}>
                        {data?.percentage && Math.abs(data?.percentage).toFixed(2)}%{' '}
                        <img src={grarrow} alt="grarrow" />
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
