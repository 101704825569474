function Typography({ text, variant, extraClasses = '', ...rest }) {
  return variant === 'h1' ? (
    <h1 {...rest} className={`${extraClasses || ''}`}>
      {text}
    </h1>
  ) : variant === 'h2' ? (
    <h2 {...rest} className={`${extraClasses || ''}`}>
      {text}
    </h2>
  ) : variant === 'h3' ? (
    <h3 {...rest} className={`${extraClasses || ''}`}>
      {text}
    </h3>
  ) : variant === 'h4' ? (
    <h4 {...rest} className={`${extraClasses || ''}`}>
      {text}
    </h4>
  ) : variant === 'h5' ? (
    <h5 {...rest} className={`${extraClasses || ''}`}>
      {text}
    </h5>
  ) : variant === 'text' ? (
    <p {...rest} className={`${extraClasses || ''}`}>
      {text}
    </p>
  ) : (
    <p className={extraClasses}>{text}</p>
  )
}

export default Typography
