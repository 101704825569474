import { BrowserRouter } from 'react-router-dom'
import Footer from './Components/footer'
import Header from './Components/header'
import Routes from './Routes/routes'
import ScrollToTop from './Components/scrollToTop'

import './App.css'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Header />
        <Routes />
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
