/* eslint-disable jsx-a11y/iframe-has-title */
import Button from '../../../Components/button'
import Typography from '../../../Components/typography'
import { exploreInfo } from '../../../Constant'

function ExploreR() {
  const handleClick = () => {
    window.open('https://rake.com/', '_blank', 'noreferrer')
  }
  return (
    <section className="explore" id="bottom">
      <div className="container flex">
        <div className="row">
          <div className="col1">
            <Typography text={exploreInfo.title} variant="h2" />
            <Typography text={exploreInfo.subTitle} variant="h3" />
            {exploreInfo.des.map((text, index) => {
              return <Typography text={text} variant="text" key={index} />
            })}

            <ul className="explreul">
              {exploreInfo.exploreIconList.map((icons, index) => {
                return (
                  <li key={index}>
                    <img src={icons.image} alt={icons.alt} />
                    {icons.description}{' '}
                  </li>
                )
              })}
            </ul>
            <Button title="Play Now" onClick={handleClick} />
          </div>
          <div className="col3">
            <iframe
              src="https://player.vimeo.com/video/902120655?h=2f3f1b5062&autoplay=1&loop=1&by&muted=1"
              style={{ width: '100%', height: '100%' }}
              frameBorder="0"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExploreR
